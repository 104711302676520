import React from 'react';
import {Button, message} from 'antd';
import {getOutlet} from 'reconnect.js';
import {BlobProvider} from '@react-pdf/renderer';
import * as ShippingNoteUtil from '../../Utils/ShippingNoteUtil';
import * as AppActions from '../../AppActions';
import AdminShippingNotePdf from './AdminShippingNotePdf';

export function MultiShippingNoteDownloadPdfButton(props) {
  const [generatedOrders, setGeneratedOrders] = React.useState([]);
  return (
    <div style={{margin: 5}}>
      <Button
        className="multi-shipping-note-download-btn"
        onClick={async () => {
          const orders = getOutlet('selected-orders').getValue();
          if (orders.length === 0) {
            message.warning('尚未選取訂單。');
            return;
          }

          const invalidOrders = [];
          AppActions.setLoading(true, {
            message: `正在處理 1 / ${orders.length}`,
          });

          try {
            const results = [];

            for (const order of orders) {
              if (
                order.logistics_subtype === 'SF' ||
                order.logistics_type === 'OVERSEA'
              ) {
                invalidOrders.push(order);
                continue;
              }

              const {data} = await ShippingNoteUtil.prepareShippingNote(order);
              results.push({order, data});
              AppActions.setLoading(true, {
                message: `正在處理 ${results.length + 1} / ${orders.length}`,
              });
            }

            setGeneratedOrders(results);
          } catch (ex) {
            console.warn(ex);
          }
          AppActions.setLoading(false);

          await AppActions.delay(1000);
          if (invalidOrders.length > 0) {
            alert(
              `注意: 以下訂單不支援此操作\n${invalidOrders
                .map((o) => o.order_id)
                .join('\n')}`,
            );
          }
        }}>
        下載托運單
      </Button>

      {generatedOrders.length > 0 && (
        <BlobProvider
          document={<AdminShippingNotePdf generatedOrders={generatedOrders} />}>
          {({...rest}) => {
            if (rest.url) {
              return (
                <a
                  href="/#"
                  style={{margin: 10}}
                  onClick={(evt) => {
                    evt.preventDefault();
                    window.open(rest.url, '_blank');
                  }}>
                  下載PDF
                </a>
              );
            } else {
              return (
                <a href="/#" style={{margin: 10}}>
                  Generating...
                </a>
              );
            }
          }}
        </BlobProvider>
      )}
    </div>
  );
}
