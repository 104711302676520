import React from 'react';
import AdminLinkButton from '../../Generators/AdminResource/AdminLinkButton';

export function renderCustomAdminSection(props) {
  const {name, type, context} = props;
  return [false, null];
}

export function renderCustomAdminCol(props) {
  const {col, record} = props;

  if (col.customType === 'fundraising-history') {
    return [
      true,
      <AdminLinkButton
        title={`${record.updates.length} 則`}
        url={`/admin/fundraising-history/?action=detail&id=${record.id}`}
      />,
    ];
  } else if (col.customType === 'product-detail') {
    return [
      true,
      <AdminLinkButton
        title={record.name}
        url={`/admin/products/?action=detail&id=${record.id}`}
      />,
    ];
  }

  return [false, null];
}
